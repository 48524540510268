const PAYMENT_STATUS = [
  {
    id: 0,
    value: 'ALL',
    name: 'Todos',
  },
  {
    id: 1,
    value: 'CANCELED',
    name: 'Cancelada',
  },
  {
    id: 2,
    value: 'VOUCHER_100',
    name: 'Voucher 100%',
  },
  {
    id: 3,
    value: 'EXTERNAL',
    name: 'Externo',
  },
  {
    id: 4,
    value: 'WAITING',
    name: 'Aguardando',
  },
  {
    id: 5,
    value: 'CONFIRMED',
    name: 'Confirmado',
  },
];

export default PAYMENT_STATUS;
