<template>
  <div class="schedule-list-view">
    <app-header icon="clipboard" title="Consultas"></app-header>

    <section>
      <div class="card">
        <header class="card-header">
          <div class="card-header-content p-4">
            <div class="row">
              <div class="col-4">
                <app-input
                  label="Filtrar por Paciente"
                  v-model="searchTerm"
                  placeholder="Pesquisar nome ou CPF"
                  icon="search"
                ></app-input>
              </div>

              <div class="col-3">
                <b-field class="period-filter" label="Período">
                  <b-select
                    @input="onPeriodChange()"
                    v-model="periodFilter"
                    expanded
                  >
                    <option
                      v-for="period in periods"
                      :key="period.id"
                      :value="period.id"
                      :disabled="period.disabled"
                    >
                      {{ period.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>

              <div v-if="showCustomPeriod" class="col-3">
                <b-field label="Selecionar período">
                  <b-datepicker
                    icon="calendar"
                    placeholder="Escolha as datas"
                    v-model="customDates"
                    @input="onRangeChange"
                    range
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
          </div>
          <div class="card-header-buttons">
            <b-tooltip label="Selecionar mais filtros" class="mr-2">
              <b-button
                @click="onFilterModalClick"
                type="is-dark"
                size="is-small"
                icon-left="plus"
              >
                Opções de filtros
              </b-button>
            </b-tooltip>

            <router-link :to="{ name: 'schedules.create' }">
              <b-tooltip label="Nova Consulta">
                <b-button type="is-primary" size="is-small" icon-left="plus">
                  Adicionar
                </b-button>
              </b-tooltip>
            </router-link>

            <b-tooltip label="Exportar planilha de consultas" class="ml-2">
              <b-button
                :disabled="!data.length"
                @click="exportSchedules()"
                type="is-dark"
                size="is-small"
                icon-left="file-excel"
              >
                Exportar
              </b-button>
            </b-tooltip>
          </div>
        </header>

        <div class="card-content">
          <b-field>
            <b-checkbox v-model="showAllColumns"
              >Mostrar todas as colunas</b-checkbox
            >
          </b-field>
          <b-table
            @page-change="onPageChange"
            :current-page.sync="page"
            :loading="isLoading"
            :per-page="perPage"
            :total="total"
            :data="data"
            paginated
            backend-pagination
            aria-page-label="Página"
            aria-current-label="Página Atual"
            aria-next-label="Próxima Página"
            aria-previous-label="Página Anterior"
          >
            <b-table-column v-slot="props" field="patient" label="Paciente">
              {{ props.row.patient.name }}
            </b-table-column>

            <b-table-column v-slot="props" field="patient" label="CPF">
              {{ formatDocument(props.row.patient.cpf) }}
            </b-table-column>

            <b-table-column v-slot="props" field="date" label="Data/Horário">
              {{ props.row.start | date('DD/MM/YYYY [às] HH:mm') }}
            </b-table-column>

            <b-table-column v-slot="props" field="type" label="Tipo">
              {{
                props.row.appointment
                  ? props.row.appointment.appointment_type_id == 2
                    ? 'Teleconsulta'
                    : 'Presencial'
                  : 'Presencial'
              }}
            </b-table-column>
            <b-table-column v-slot="props" field="value" label="Valor">
              R$
              {{ getMoneyValue(props.row.value) }}
            </b-table-column>

            <b-table-column v-slot="props" field="value" label="Taxas">
              R$
              {{ getMoneyValue(props.row.value_fee) }}
            </b-table-column>

            <b-table-column v-slot="props" field="status" label="Status">
              <b-tag :type="`is-${props.row.schedule_status.type}`">
                {{ props.row.schedule_status.label }}
              </b-tag>
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="payment_status"
              label="Status Pgto"
            >
              <div v-if="!props.row.payment_status">-</div>
              <div v-if="props.row.payment_status">
                <b-tag :type="`is-${props.row.payment_status.type}`">
                  {{ props.row.payment_status.label }}
                </b-tag>
              </div>
            </b-table-column>

            <b-table-column
              :visible="showAllColumns"
              v-slot="props"
              field="procedure"
              label="Procedimento"
            >
              {{ props.row.procedure ? props.row.procedure.name : '-' }}
            </b-table-column>

            <b-table-column v-slot="props" label="Opções">
              <router-link
                :to="{
                  name: 'schedules.view',
                  params: { id: props.row.id },
                }"
              >
                <b-tooltip label="Visualizar">
                  <b-button
                    type="is-secondary"
                    size="is-small"
                    icon-right="eye"
                  />
                </b-tooltip>
              </router-link>

              <b-tooltip label="Visualizar prescrições" position="is-left">
                <b-button
                  @click="openPrescriptions(props.row.id)"
                  type="is-secondary"
                  size="is-small"
                  icon-right="prescription-bottle-alt"
                />
              </b-tooltip>

              <b-tooltip label="Visualizar prontuários" position="is-left">
                <b-button
                  @click="openRecords(props.row.id)"
                  type="is-secondary"
                  size="is-small"
                  icon-right="clipboard"
                />
              </b-tooltip>

              <!-- <b-tooltip
                v-if="props.row.checkout_url"
                position="is-left"
                label="Copiar link de pagamento"
              >
                <b-button
                  @click="copyCheckouUrl(props.row.checkout_url)"
                  type="is-secondary"
                  size="is-small"
                  icon-right="link"
                />
              </b-tooltip> -->
            </b-table-column>

            <template slot="footer">
              <div class="row">
                <div class="col-12">
                  <div class="has-text-right">
                    <div>
                      <b>Valores conforme o filtro:</b>
                      <br />
                    </div>

                    <div>
                      <b>Total:</b> R$
                      {{ schedule_values.search_total }}
                    </div>

                    <div>
                      Valor médio das consultas: R${{ schedule_values.average }}
                    </div>

                    <div>
                      <b>Total na página:</b> R$
                      {{ schedule_values.paginated_total }}
                    </div>

                    <div>
                      <b>Total de consultas: {{ total }}</b>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>Nenhum registro encontrado.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MedicalRecordsList from '@/modals/MedicalRecordsList.vue';
import PrescriptionsList from '@/modals/PrescriptionsList.vue';
import SchedulesService from '@/services/schedules.service';
import DashboardService from '@/services/dashboard.service';

import PERIODS from '@/constants/periods.constants';
import PAYMENT_STATUS from '@/constants/payment_status.constants';
import debounce from 'lodash/debounce';

import * as moment from 'moment';
import ProceduresService from '@/services/procedures.service';

import BuefyMixin from '@/mixins/BuefyMixin';

import SchedulesFilterModal from '@/modals/SchedulesFilterModal.vue';

export default {
  mixins: [BuefyMixin],
  data: () => ({
    periods: PERIODS,
    paymentStatus: PAYMENT_STATUS,
    showAllColumns: false,
    showCustomPeriod: false,
    isLoading: true,
    periodFilter: 6,
    paymentStatusFilter: 'ALL',
    searchTerm: '',
    type: 0,
    status: 'ALL',
    dates: [],
    customDates: null,
    data: [],
    total: 0,
    page: 1,
    perPage: 10,
    period: [],
    total_value: 0,
    search_value: 0,
    totalValue: 0,
    total_carried_out: 0,
    total_schedules: 0,
    schedule_time: 0,
    procedures: [],
    searchProcedure: null,
    balance_info: {
      balance: null,
      withheld: null,
      transferable: null,
      gateway_fee: null,
    },
    schedule_values: {},
  }),
  watch: {
    searchTerm: debounce(function () {
      this.page = 1;
      this.loadAppointments();
    }, 400),
  },
  methods: {
    loadScheduleValues() {
      const params = {};

      if (this.dates.length == 2) {
        params['dates'] = `${this.dates[0]},${this.dates[1]}`;
      }

      if (this.searchTerm) {
        params['patient_info'] = this.searchTerm;
      }

      if (this.type >= 0) {
        params['type'] = this.type;
      }

      if (this.searchProcedure) {
        params['procedure'] = this.searchProcedure;
      }

      if (this.status) {
        params['status'] = this.status;
      }

      if (this.paymentStatusFilter) {
        params['payment_status'] = this.paymentStatusFilter;
      }

      DashboardService.getScheduleListValues({
        ...params,
      }).then(({ data: values }) => {
        let paginatedValues = 0;
        this.data.forEach((data) => (paginatedValues += Number(data.value)));

        this.schedule_values = {
          ...values,
          paginated_total: paginatedValues.toFixed(2),
        };
      });
    },
    loadAppointments() {
      this.isLoading = true;

      const params = {
        page: this.page,
        perPage: this.perPage,
      };

      if (this.dates.length == 2) {
        params[
          'filter[start_end_between]'
        ] = `${this.dates[0]},${this.dates[1]}`;
      }

      if (this.searchTerm) {
        params['filter[patient_info]'] = this.searchTerm;
      }

      if (this.type >= 0) {
        params['filter[appointment_type]'] = this.type;
      }

      if (this.searchProcedure) {
        params['filter[procedure_id]'] = this.searchProcedure;
      }

      if (this.status) {
        params['filter[status]'] = this.status;
      }

      if (this.paymentStatusFilter) {
        params['filter[payment_status]'] = this.paymentStatusFilter;
      }

      SchedulesService.get({ ...params })
        .then(({ data }) => {
          const { total, data: items, current_page } = data;

          this.total = total;
          this.page = current_page;
          this.data = Object.values(items);
        })
        .finally(() => {
          this.loadScheduleValues();
          this.isLoading = false;
        });
    },

    formatDocument(value) {
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    },
    onPageChange(page) {
      this.page = page;
      this.loadAppointments();
    },
    onRangeChange(value) {
      this.dates = value.map((date) => moment(date).format('YYYY-MM-DD'));
      this.loadAppointments();
    },
    onPeriodChange() {
      const period = this.periods.find((item) => item.id == this.periodFilter);
      this.page = 1;
      this.showCustomPeriod = false;

      if (period.clear) {
        this.dates = [];
        this.loadAppointments();
      } else if (period.custom) {
        this.showCustomPeriod = true;
        this.dates = null;
      } else {
        const { start, end } = period;

        this.dates = [
          moment(start).format('YYYY-MM-DD'),
          moment(end).format('YYYY-MM-DD'),
        ];

        this.loadAppointments();
      }
    },
    getMoneyValue(value) {
      if (!value) return (0).toFixed(2);

      return Number(value).toFixed(2);
    },

    openPrescriptions(scheduleId) {
      this.$buefy.modal.open({
        parent: this,
        component: PrescriptionsList,
        hasModalCard: true,
        trapFocus: true,
        canCancel: ['escape', 'x', 'outside'],
        props: {
          scheduleId,
        },
      });
    },
    openRecords(scheduleId) {
      this.$buefy.modal.open({
        parent: this,
        component: MedicalRecordsList,
        hasModalCard: true,
        trapFocus: true,
        canCancel: ['escape', 'x', 'outside'],
        props: {
          scheduleId,
        },
      });
    },
    onFilterModalClick() {
      this.$buefy.modal.open({
        parent: this,
        component: SchedulesFilterModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          type: this.type,
          status: this.status,
          perPage: this.perPage,
          procedures: this.procedures,
          procedure: this.searchProcedure,
          paymentStatus: this.paymentStatus,
          selectedPayment: this.paymentStatusFilter,
        },
        events: {
          close: (data) => data && this.applyFilters(data),
        },
      });
    },
    applyFilters(filters = {}) {
      Object.keys(filters).forEach((key) => {
        if (key == 'type') this.type = filters[key];
        if (key == 'status') this.status = filters[key];
        if (key == 'perPage') this.perPage = filters[key];
        if (key == 'searchProcedure') this.searchProcedure = filters[key];
        if (key == 'paymentStatusFilter')
          this.paymentStatusFilter = filters[key];
      });

      this.loadAppointments();
    },
    exportSchedules() {
      const params = {};

      if (this.searchTerm) {
        params.patient_name = this.searchTerm;
      }

      if (this.type >= 0) {
        params.type = this.type;
      }

      if (this.dates.length) {
        params.range = `${this.dates[0]},${this.dates[1]}`;
      }

      SchedulesService.export({ ...params })
        .then(({ data }) => {
          const fileName = data.split('/')[data.split('/').length - 1];
          const fileLink = document.createElement('a');

          fileLink.href = data;
          fileLink.setAttribute('download', fileName);
          fileLink.setAttribute('style', 'display:none;');

          document.body.appendChild(fileLink);
          fileLink.click();

          fileLink.remove();
        })
        .catch(({ response }) => {
          const message =
            response.data.message || 'Erro ao realizar exportação.';
          this.$buefy.snackbar.open(message);
        });
    },

    loadProcedures() {
      this.isLoading = true;
      ProceduresService.get({})
        .then(({ data }) => {
          this.procedures = data;
        })
        .finally(() => setTimeout(() => (this.isLoading = false), 300));
    },

    copyCheckouUrl(url) {
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.value = url;

      input.focus();
      input.select();

      if (document.execCommand('copy')) {
        this.$buefy.snackbar.open({
          message: 'Link copiado com sucesso!',
          type: 'is-success',
        });
      }

      document.body.removeChild(input);
    },
  },
  mounted() {
    this.onPeriodChange();
    this.loadProcedures();
  },
};
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  margin-left: -15px;
  padding-bottom: 15px;
}
.table {
  span {
    margin-right: 10px !important;
    margin-bottom: 3px !important;
  }
}
</style>
