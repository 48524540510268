<template>
  <div class="prescriptions-list-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Prescrições</p>
      </header>
      <section class="modal-card-body">
        <div class="card">
          <div class="card-content custom-scroll">
            <prescriptions-table
              ref="prescriptionsTable"
              @open="openPrescription($event)"
              :scheduleId="scheduleId"
              :listOnly="true"
            ></prescriptions-table>
          </div>
        </div>
      </section>

      <footer class="modal-card-foot is-right">
        <b-button @click="$emit('close')">Fechar</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import PrescriptionsTable from '@/components/PrescriptionsTable.vue';
import Prescription from '@/modals/Prescription.vue';

export default {
  components: { PrescriptionsTable },
  props: {
    scheduleId: Number,
  },
  methods: {
    openPrescription(prescription) {
      this.$buefy.modal.open({
        parent: this,
        component: Prescription,
        hasModalCard: true,
        trapFocus: true,
        props: {
          prescription,
          scheduleId: this.scheduleId,
          viewOnly: true,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
