<template>
  <div class="medical-records-list-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Prontuários</p>
      </header>

      <section class="modal-card-body">
        <div class="card">
          <div class="card-content custom-scroll">
            <medical-records-table
              ref="prescriptionsTable"
              @open="openRecord($event)"
              :scheduleId="scheduleId"
              :listOnly="true"
            ></medical-records-table>
          </div>
        </div>
      </section>

      <footer class="modal-card-foot is-right">
        <b-button @click="$emit('close')">Fechar</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import MedicalRecordsTable from '@/components/MedicalRecordsTable.vue';
import MedicalRecord from '@/modals/MedicalRecord.vue';

export default {
  components: { MedicalRecordsTable },
  props: {
    scheduleId: Number,
  },
  methods: {
    openRecord(record) {
      this.$buefy.modal.open({
        parent: this,
        component: MedicalRecord,
        hasModalCard: true,
        trapFocus: true,
        props: {
          record,
          scheduleId: this.scheduleId,
          viewOnly: true,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
