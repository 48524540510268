import { render, staticRenderFns } from "./PrescriptionsList.vue?vue&type=template&id=19545727&scoped=true&"
import script from "./PrescriptionsList.vue?vue&type=script&lang=js&"
export * from "./PrescriptionsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19545727",
  null
  
)

export default component.exports