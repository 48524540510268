<template>
  <div class="balance-transfer-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Selecionar filtros</p>
      </header>
      <section class="modal-card-body">
        <div class="row">
          <div class="col-6">
            <b-field label="Tipo da consulta">
              <b-select
                v-model="selectedType"
                placeholder="Selecionar..."
                expanded
              >
                <option :value="0" :key="0">Ambos</option>
                <option :value="1" :key="1">Presencial</option>
                <option :value="2" :key="2">Teleconsulta</option>
              </b-select>
            </b-field>
          </div>
          <div class="col-6">
            <b-field label="Status da consulta">
              <b-select
                v-model="selectedStatus"
                placeholder="Selecionar..."
                expanded
              >
                <option :value="'ALL'" :key="0">Todos</option>
                <option :value="'CANCELED'" :key="1">Canceladas</option>
                <option :value="'PERFORMED'" :key="2">Realizadas</option>
                <option :value="'TO_PERFORM'" :key="3">A realizar</option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-4">
            <b-field label="Status de pagamento">
              <b-select v-model="selectedPaymentStatus" expanded>
                <option :value="null">Selecionar</option>
                <option
                  v-for="status in paymentStatus"
                  :key="status.id"
                  :value="status.value"
                >
                  {{ status.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="col-4">
            <b-field label="Procedimento">
              <b-select v-model="selectedProcedure" expanded>
                <option :value="null">Tudo</option>
                <option
                  v-for="procedure in procedures"
                  :key="procedure.id"
                  :value="procedure.id"
                >
                  {{ procedure.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="col-4">
            <app-input
              label="Resultados por página"
              v-model="selectedPerPage"
              type="number"
              min="1"
            ></app-input>
          </div>
        </div>
      </section>

      <footer class="modal-card-foot is-right">
        <b-button @click="$emit('close', false)">Cancelar</b-button>
        <b-button @click="applyFilter" type="is-primary"
          >Filtrar consultas</b-button
        >
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: Number,
      default: () => 0,
    },
    status: {
      type: String,
      default: () => 'ALL',
    },
    procedures: {
      type: Array,
      default: () => [],
    },
    procedure: {
      type: Number,
      default: () => 0,
    },
    selectedPayment: {
      type: String,
    },
    paymentStatus: {
      type: Array,
      default: () => [],
    },
    perPage: {
      type: Number,
      default: () => 10,
    },
  },

  data: () => ({
    selectedPaymentStatus: null,
    selectedProcedure: null,
    selectedPerPage: null,
    selectedStatus: null,
    selectedType: null,
  }),
  methods: {
    applyFilter() {
      const filters = {
        type: this.selectedType,
        status: this.selectedStatus,
        perPage: this.selectedPerPage,
        searchProcedure: this.selectedProcedure,
        paymentStatusFilter: this.selectedPaymentStatus,
      };

      this.$emit('close', filters);
    },
  },
  beforeMount() {
    this.selectedPaymentStatus = this.selectedPayment;
    this.selectedProcedure = this.procedure;
    this.selectedPerPage = this.perPage;
    this.selectedStatus = this.status;
    this.selectedType = this.type;
  },
};
</script>

<style lang="scss" scoped></style>
